import React, { useState } from "react";
import TeamsNav from "../teams/TeamsNav";
import { Tab, Tabs } from "react-bootstrap";
import Rules from "./rules";
import Kontakt from "./kontakt";
import GalleryIndex from "./galleryIndex";

const Infos = () => {
  const [key, setKey] = useState("teams");

  return (
    <div>
      <Tabs className="mt-1" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab
          eventKey={"teams"}
          title="Teams"
          tabClassName="text-white bg-transparent pt-1 pb-1 ps-2 pe-2"
        >
          <TeamsNav />
        </Tab>
        <Tab
          eventKey={"regeln"}
          title="Regeln"
          tabClassName="text-white bg-transparent pt-1 pb-1 ps-2 pe-2"
        >
          <Rules />
        </Tab>
        <Tab
          eventKey={"kontakt"}
          title="Kontakt"
          tabClassName="text-white bg-transparent pt-1 pb-1 ps-2 pe-2"
        >
          <Kontakt />
        </Tab>
        <Tab
          eventKey={"galerie"}
          title="Galerie"
          tabClassName="text-white bg-transparent  pt-1 pb-1 ps-2 pe-2"
        >
          <GalleryIndex />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Infos;
