import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import NavBar from "./components/layout/navbar";
import "./App.css";
import { Container } from "react-bootstrap";
import Home from "./components/home/home";
import TabelleDetail from "./components/tabelle/Tabelle";
import SpielplanDetail from "./components/spielplan/spielplan";
import TeamDetail from "./components/teams/TeamDetail";
import Admin from "./components/admin/admin";
import List from "./components/scorer/list";
import Game from "./components/game/game";
import Infos from "./components/infos";

function App() {
  return (
    <div
      className="app ps-2 pe-2 pt-4 pb-4 p-lg-4"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <Container
        fluid
        className="rounded glass d-table text-white pb-3"
        style={{ height: "95vh" }}
      >
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/tabelle" element={<TabelleDetail />} />
            <Route path="/scorer" element={<List />} />
            <Route path="/spielplan" element={<SpielplanDetail />} />
            <Route path="/team/:id" element={<TeamDetail />} />
            <Route path="/infos" element={<Infos />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/game/:id" element={<Game />} />
          </Routes>
        </Router>
      </Container>
    </div>
  );
}

export default App;
