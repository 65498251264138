import { SET_STATS_HR, SET_STATS_PO } from "../constants/actionTypes";

const initialState = {
  HRStats: [],
  POStats: [],
};

const Stats = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATS_HR:
      return { ...state, HRStats: action.payload };
    case SET_STATS_PO:
      return { ...state, POStats: action.payload };
    default:
      return state;
  }
};

export default Stats;
