import React, { useState, useEffect } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import Header from "../home/Header";
import { GET } from "../tools/fetch";
import KaderPlayer from "./KaderPlayer";
import TeamLogo from "./Logo";
import Team from "./Team";
import "./TeamDetail.css";

const TeamDetail = () => {
  const { id } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [kader, setKader] = useState();
  const [games, setGames] = useState();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState();
  const teams = useSelector((state) => state.teams.teams);
  const team = teams.find((team) => team.id === +id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const getKader = async () => {
    try {
      setLoading(true);
      const kader = await GET(`/team/kader?team=${id}`);
      setKader(kader.kader);
      const getGames = await GET(`/spiel/team/${id}`);
      setGames(getGames.games);
      const stats = await GET("/team/details");
      setDetails(stats);
      dispatch({ type: "ADD_TABELLE", payload: stats.details });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKader();
  }, [id]);

  const now = new Date();

  return (
    <div className="mt-2">
      <Row className="border-bottom border-secondary">
        <Col xs="auto">
          <TeamLogo team={id} detail={true} />
        </Col>
        <Col>
          <div className="h3">
            {team.name}{" "}
            {!isMobile && team.instagram !== "" && (
              <a
                style={{ verticalAlign: "text-top" }}
                className="text-white"
                href={`https://www.instagram.com/${team.instagram}`}
                target="_blank"
              >
                <img
                  src={require("../../images/Sonstiges/insta.png")}
                  style={{ width: "20px" }}
                />
              </a>
            )}
            {isMobile && team.instagram !== "" && (
              <a
                className="text-white"
                href={`instagram://user?username=${team.instagram}`}
              >
                <img
                  src={require("../../images/Sonstiges/insta.png")}
                  style={{ width: "15px" }}
                />
              </a>
            )}
          </div>
          <div>{team.erfolge !== "" && "Erfolge: " + team.erfolge}</div>
          <div>{loading === false && kader.length} Spieler</div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="p-0">
          <Header title="Kader" />
          <Table
            responsive
            size="sm"
            className="g-0 table-borderless text-white"
          >
            <thead className="border-bottom">
              <tr className="small">
                <th>Tor</th>
                <th></th>
                <th className="text-center">PO?</th>
                <th>Sp.</th>
                <th>Pkt.</th>
                <th>T</th>
                <th>A</th>
                <th>Str.</th>
              </tr>
            </thead>
            <tbody>
              {loading === false &&
                Object.values(kader).map((player) => {
                  if (player.position === 1) {
                    return (
                      <KaderPlayer
                        key={player.id}
                        player={player.playerDetails}
                        nummer={player.nummer}
                        team={id}
                        position={player.position}
                        awards={player.awards}
                        mobile={isMobile}
                      />
                    );
                  }
                })}
            </tbody>
            <thead className="border-bottom">
              <tr className="small">
                <th>Verteidigung</th>
                <th></th>
                <th className="text-center">PO?</th>
                <th>Sp.</th>
                <th>Pkt.</th>
                <th>T</th>
                <th>A</th>
                <th>Str.</th>
              </tr>
            </thead>
            <tbody>
              {loading === false &&
                Object.values(kader).map((player) => {
                  if (player.position === 2) {
                    return (
                      <KaderPlayer
                        key={player.id}
                        player={player.playerDetails}
                        nummer={player.nummer}
                        team={id}
                        position={player.position}
                        awards={player.awards}
                        mobile={isMobile}
                      />
                    );
                  }
                })}
            </tbody>
            <thead className="border-bottom">
              <tr className="small">
                <th>Sturm</th>
                <th></th>
                <th className="text-center">PO?</th>
                <th>Sp.</th>
                <th>Pkt.</th>
                <th>T</th>
                <th>A</th>
                <th>Str.</th>
              </tr>
            </thead>
            <tbody>
              {loading === false &&
                Object.values(kader).map((player) => {
                  if (player.position === 3) {
                    return (
                      <KaderPlayer
                        key={player.id}
                        player={player.playerDetails}
                        nummer={player.nummer}
                        team={id}
                        position={player.position}
                        awards={player.awards}
                        mobile={isMobile}
                      />
                    );
                  }
                })}
            </tbody>
          </Table>
        </Col>
        <Col xs="12" md="6">
          <div>
            <Header title="Statistik" />
            <small className="d-block text-center">(All-Time)</small>
            {loading === false && (
              <Row>
                <Col
                  xs="12"
                  md="6"
                  className="mt-5 mb-2 d-flex justify-content-evenly"
                >
                  <div className="progress progress-bar-vertical">
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 0,
                          "& .MuiTooltip-popper": { zIndex: 0 },
                          "& .MuiTooltip-popperInteractive": { zIndex: 0 },
                          "& .MuiTooltip-tooltipPlacementTop": { zIndex: 0 },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#3c996e",
                            zIndex: 0,
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#3c996e",
                            zIndex: 0,
                          },
                        },
                      }}
                      open={true}
                      disableHoverListener
                      disableTouchListener
                      title={team.WINS}
                      arrow
                      placement="top"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-success"
                        role="progressbar"
                        style={{
                          height: team.w_p + "%",
                        }}
                        aria-valuenow={team.w_p}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        S
                      </div>
                    </Tooltip>
                  </div>
                  <div className="progress progress-bar-vertical">
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 0,
                          "& .MuiTooltip-popper": { zIndex: 0 },
                          "& .MuiTooltip-popperInteractive": { zIndex: 0 },
                          "& .MuiTooltip-tooltipPlacementTop": { zIndex: 0 },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#828a90",
                            zIndex: 0,
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#828a90",
                            zIndex: 0,
                          },
                        },
                      }}
                      open={true}
                      disableHoverListener
                      disableTouchListener
                      title={team.DRAW}
                      arrow
                      placement="top"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-secondary"
                        role="progressbar"
                        style={{
                          height: team.d_p + "%",
                        }}
                        aria-valuenow={team.d_p}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        U
                      </div>
                    </Tooltip>
                  </div>
                  <div className="progress progress-bar-vertical">
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 0,
                          "& .MuiTooltip-popper": { zIndex: 0 },
                          "& .MuiTooltip-popperInteractive": { zIndex: 0 },
                          "& .MuiTooltip-tooltipPlacementTop": { zIndex: 0 },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#e15361",
                            zIndex: 0,
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#e15361",
                            zIndex: 0,
                          },
                        },
                      }}
                      open={true}
                      disableHoverListener
                      disableTouchListener
                      title={team.LOSS}
                      arrow
                      placement="top"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-danger"
                        role="progressbar"
                        style={{
                          height: team.l_p + "%",
                        }}
                        aria-valuenow={team.l_p}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        N
                      </div>
                    </Tooltip>
                  </div>
                </Col>
                <Col
                  xs="12"
                  md="6"
                  className="mt-5 mb-2 d-flex justify-content-evenly"
                >
                  <div className="progress progress-bar-vertical">
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 0,
                          "& .MuiTooltip-popper": { zIndex: 0 },
                          "& .MuiTooltip-popperInteractive": { zIndex: 0 },
                          "& .MuiTooltip-tooltipPlacementTop": { zIndex: 0 },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#3c996e",
                            zIndex: 0,
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#3c996e",
                            zIndex: 0,
                          },
                        },
                      }}
                      open={true}
                      disableHoverListener
                      disableTouchListener
                      title={team.G}
                      arrow
                      placement="top"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-success"
                        role="progressbar"
                        style={{
                          height: team.g_p + "%",
                        }}
                        aria-valuenow={team.g_p}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        T
                      </div>
                    </Tooltip>
                  </div>
                  <div className="progress progress-bar-vertical">
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 0,
                          "& .MuiTooltip-popper": { zIndex: 0 },
                          "& .MuiTooltip-popperInteractive": { zIndex: 0 },
                          "& .MuiTooltip-tooltipPlacementTop": { zIndex: 0 },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#e15361",
                            zIndex: 0,
                          },
                          "& .MuiTooltip-arrow": {
                            color: "#e15361",
                            zIndex: 0,
                          },
                        },
                      }}
                      open={true}
                      disableHoverListener
                      disableTouchListener
                      title={team.GA}
                      arrow
                      placement="top"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-danger"
                        role="progressbar"
                        style={{
                          height: team.ga_p + "%",
                        }}
                        aria-valuenow={team.ga_p}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        GT
                      </div>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <div>
            <Header title="Spielplan" />
            {loading === false &&
              Object.values(games).map((game) => {
                const time = new Date(game.time);
                const date = new Date(game.date);
                return (
                  <Row
                    key={game.id}
                    className="pb-1 mb-1 border-bottom border-secondary text-center align-items-center"
                  >
                    <Col className="g-0 gx-xl-4" xs="2">
                      <TeamLogo team={game.home_team.id} />
                    </Col>
                    <Col className="g-0">
                      <Team team={game.home_team} />
                    </Col>
                    <Col
                      className="g-0 gx-xl-4"
                      md={{ span: "2", order: "1" }}
                      xs={
                        game.bericht !== 1
                          ? { span: "12", order: "5" }
                          : { span: "2" }
                      }
                    >
                      {game.bericht === 1 && (
                        <div
                          onClick={() => navigate(`/game/${game.id}`)}
                          style={{ cursor: "pointer" }}
                        >
                          <b>
                            <u>
                              {game.score_home}
                              {" : "}
                              {game.score_away}
                            </u>
                          </b>
                        </div>
                      )}
                      {!isMobile && (
                        <span style={{ fontSize: "calc(11px + 0.50625vw)" }}>
                          {!isMobile && game.bericht !== 1 && <div>vs.</div>}
                          <div className="small">
                            {now.toDateString() === date.toDateString()
                              ? "Heute"
                              : date.toLocaleDateString("de-DE", {
                                  dateStyle: "short",
                                })}
                            ,{" "}
                            {time.toLocaleTimeString("de-DE", {
                              timeZone: "UTC",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            Uhr
                          </div>
                        </span>
                      )}
                    </Col>
                    {isMobile && (
                      <Col
                        style={{ fontSize: "calc(11px + 0.50625vw)" }}
                        className="g-0 gx-xl-4"
                        md={{ span: "2", order: "1" }}
                        xs={{ span: "12", order: "5" }}
                      >
                        <div className="small">
                          {now.toDateString() === date.toDateString()
                            ? "Heute"
                            : date.toLocaleDateString("de-DE", {
                                dateStyle: "short",
                              })}
                          ,{" "}
                          {time.toLocaleTimeString("de-DE", {
                            timeZone: "UTC",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          Uhr
                        </div>
                      </Col>
                    )}
                    <Col className="g-0" md={{ order: "2" }}>
                      <Team team={game.away_team} />
                    </Col>
                    <Col className="g-0 gx-xl-4" md={{ order: "3" }} xs="2">
                      <TeamLogo team={game.away_team.id} />
                    </Col>
                  </Row>
                );
              })}
          </div>
        </Col>
      </Row>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default TeamDetail;
