const initialState = [
  "6-Mann",
  "Bandencheck",
  "Behinderung",
  "Beinstellen",
  "Check gg. das Knie",
  "Check gg. Kopf/Nacken",
  "Check mit dem Knie",
  "Check von Hinten",
  "Disziplinarstrafe",
  "Ellbogencheck",
  "Halten",
  "Halten des Stocks",
  "Haken",
  "Hoher Stock",
  "Spielverzögerung",
  "Stock-Check",
  "Stockschlag",
  "Stockstich",
  "Torwart Behinderung",
  "Unsportliches Verhalten",
  "Unerlaubter Körperangriff",
  "Übertriebene Härte",
];
const Strafen = (state = initialState, action) => {
  return state;
};

export default Strafen;
