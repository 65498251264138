import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const SeasonModal = (props) => {
  const [beg, setBeg] = useState("0000-00-00");
  const [end, setEnd] = useState("0000-00-00");

  const handleDateChange = (e) => {
    if (e.target.name === "beg") {
      setBeg(e.target.value);
    } else {
      setEnd(e.target.value);
    }
  };

  const resetInput = () => {
    setBeg("0000-00-00");
    setEnd("0000-00-00");
  };

  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Saison anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row className="text-center">
          <Col>
            <TextField
              name="beg"
              size="small"
              label="Beginn"
              type="date"
              value={beg}
              onChange={(e) => handleDateChange(e)}
            />
          </Col>
          <Col>
            <TextField
              name="end"
              size="small"
              label="Ende"
              type="date"
              value={end}
              onChange={(e) => handleDateChange(e)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button size="sm" onClick={resetInput} variant="outline-danger">
          Eingaben löschen
        </Button>
        <Button size="sm" variant="outline-success">
          anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SeasonModal;
