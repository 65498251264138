import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { POST } from "../tools/fetch";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const PlayerModal = (props) => {
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [team, setTeam] = useState([{ team_id: 0 }]);
  const [nummer, setNummer] = useState([{ nummer: 0 }]);
  const [position, setPosition] = useState([{ position: 0 }]);
  const teams = useSelector((state) => state.teams.teams);

  const send = async () => {
    try {
      const post = await POST("/spieler/add", {
        vorname: vorname,
        nachname: nachname,
        team: team,
        position: position,
        nummer: nummer,
      });
    } catch (error) {
    } finally {
      setVorname("");
      setNachname("");
      setTeam([{ team_id: 0 }]);
      setPosition([{ position: 0 }]);
      setNummer([{ nummer: 0 }]);
    }
  };

  const HandleRemoveTeam = (id) => {
    //setConfirmRemove(true);
    team.splice(id, 1);
    setTeam([...team]);
    position.splice(id, 1);
    setPosition([...position]);
    nummer.splice(id, 1);
    setNummer([...nummer]);
  };

  const handleTeamChange = (e) => {
    let id = 0;
    if (e.target.name !== "0") {
      id = +e.target.name;
    } else {
      id = 0;
    }
    team[id] = { ...team[id], team_id: e.target.value };
    setTeam([...team]);
  };

  const addTeam = () => {
    if (team.length < teams.length) {
      position.push({ position: 0 });
      setPosition([...position]);
      team.push({ team_id: 0 });
      setTeam([...team]);
      nummer.push({ nummer: 0 });
      setNummer([...nummer]);
    }
  };

  const handleNummerChange = (e) => {
    let id = 0;
    if (e.target.id !== "0") {
      id = +e.target.id;
    } else {
      id = 0;
    }
    nummer[id] = { ...nummer[id], nummer: e.target.value };
    setNummer([...nummer]);
  };

  const handlePositionChange = (e) => {
    let id = 0;
    if (e.target.name !== "0") {
      id = +e.target.name;
    } else {
      id = 0;
    }
    position[id] = { ...position[id], position: e.target.value };
    setPosition([...position]);
  };

  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Spieler anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row className="text-center">
          <Col>
            <TextField
              size="small"
              type="text"
              label="Vorname"
              onChange={(e) => setVorname(e.target.value)}
              value={vorname}
            />
          </Col>
          <Col>
            <TextField
              size="small"
              type="text"
              label="Nachname"
              onChange={(e) => setNachname(e.target.value)}
              value={nachname}
            />
          </Col>
        </Row>
        <br />
        <div className="mb-2 text-center">
          Spieler diese Saison einem Team zuweisen{" "}
          <ControlPointIcon
            fontSize="sm"
            onClick={addTeam}
            style={{ cursor: "pointer" }}
          />
        </div>
        {team.map((team_temp, id) => {
          return (
            <Row key={id} className="text-center mb-2">
              {team.length > 1 && (
                <Col xs="auto">
                  <RemoveCircleOutlineIcon
                    fontSize="sm"
                    style={{ cursor: "pointer" }}
                    onClick={() => HandleRemoveTeam(id)}
                  />
                </Col>
              )}
              <Col>
                <FormControl size="small">
                  <InputLabel>Team</InputLabel>
                  <Select
                    name={`${id}`}
                    label="Team"
                    value={team[id].team_id ?? 0}
                    onChange={(e) => handleTeamChange(e)}
                  >
                    <MenuItem value={0}>wählen</MenuItem>
                    {teams.map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col>
                <FormControl size="small" sx={{ minWidth: "100px" }}>
                  <InputLabel>Position</InputLabel>
                  <Select
                    name={`${id}`}
                    label="Position"
                    value={position[id].position ?? 0}
                    onChange={(e) => handlePositionChange(e)}
                  >
                    <MenuItem value={0}>wählen</MenuItem>
                    <MenuItem value={1}>Tor</MenuItem>
                    <MenuItem value={2}>Verteidiger</MenuItem>
                    <MenuItem value={3}>Stürmer</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              <Col>
                <TextField
                  size="small"
                  type="number"
                  label="Nummer"
                  id={`${id}`}
                  onChange={(e) => handleNummerChange(e)}
                  value={nummer[id].nummer ?? 0}
                />
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button
          onClick={send}
          size="sm"
          variant="outline-success"
          disabled={team[0].team_id === 0 || vorname === "" || nachname === ""}
        >
          anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlayerModal;
