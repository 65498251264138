import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import GameEditModal from "./GameEditModal";
import GameModal from "./GameModal";
import PlayerEditModal from "./PlayerEditModal";
import PlayerModal from "./PlayerModal";
import SeasonEditModal from "./SeasonEditModal";
import SeasonModal from "./SeasonModal";
import TeamEditModal from "./TeamEditModal";
import TeamModal from "./TeamModal";
import GameEditModalPlayoff from "./GameEditModalPlayoff";

const Admin = () => {
  const [gameModal, setGameModal] = useState(false);
  const [gameEditModal, setGameEditModal] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const [playerEditModal, setPlayerEditModal] = useState(false);
  const [seasonModal, setSeasonModal] = useState(false);
  const [seasonEditModal, setSeasonEditModal] = useState(false);
  const [teamModal, setTeamModal] = useState(false);
  const [teamEditModal, setTeamEditModal] = useState(false);
  const [playoffModal, setPlayoffModal] = useState(false);

  const createPOPlan = async () => {};

  return (
    <div>
      <div className="d-block text-center h3 m-2">Admin - Panel</div>
      <Row>
        <Col>
          <Card
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
            onClick={() => setGameModal(true)}
          >
            <Card.Body>Spiel anlegen</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={() => setGameEditModal(true)}
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
          >
            <Card.Body>Spiel bearbeiten</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
            onClick={() => setPlayerModal(true)}
          >
            <Card.Body>Spieler anlegen</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={() => setPlayerEditModal(true)}
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
          >
            <Card.Body>Spieler bearbeiten</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
            onClick={() => setSeasonModal(true)}
          >
            <Card.Body>Saison anlegen</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={() => setSeasonEditModal(true)}
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
          >
            <Card.Body>Saison bearbeiten</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
            onClick={() => setTeamModal(true)}
          >
            <Card.Body>Team anlegen</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={() => setTeamEditModal(true)}
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
          >
            <Card.Body>Team bearbeiten</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
            onClick={() => createPOPlan()}
          >
            <Card.Body>Playoff-Spielplan anlegen</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            onClick={() => setPlayoffModal(true)}
            className="shadow text-center"
            style={{
              cursor: "pointer",
              backgroundColor: "rgba(255,255,255,0.2)",
            }}
          >
            <Card.Body>Playoff-Spiel bearbeiten</Card.Body>
          </Card>
        </Col>
      </Row>
      <GameModal show={gameModal} hide={() => setGameModal(false)} />
      <GameEditModal
        show={gameEditModal}
        hide={() => setGameEditModal(false)}
      />
      <PlayerModal show={playerModal} hide={() => setPlayerModal(false)} />
      <PlayerEditModal
        show={playerEditModal}
        hide={() => setPlayerEditModal(false)}
      />
      <SeasonModal show={seasonModal} hide={() => setSeasonModal(false)} />
      <SeasonEditModal
        show={seasonEditModal}
        hide={() => setSeasonEditModal(false)}
      />
      <TeamModal show={teamModal} hide={() => setTeamModal(false)} />
      <TeamEditModal
        show={teamEditModal}
        hide={() => setTeamEditModal(false)}
      />
      <GameEditModalPlayoff
        show={playoffModal}
        hide={() => setPlayoffModal(false)}
      />
    </div>
  );
};

export default Admin;
