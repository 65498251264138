import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GET, POST } from "../tools/fetch";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const PlayerEditModal = (props) => {
  const [player, setPlayer] = useState(0);
  const [search, setSearch] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [team, setTeam] = useState([{ team_id: 0 }]);
  const [nummer, setNummer] = useState([{ nummer: 0 }]);
  const [position, setPosition] = useState([{ position: 0 }]);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState("");
  const teams = useSelector((state) => state.teams.teams);

  const searchDB = async () => {
    const response = await GET(`/spieler/search?search=${search}`);
    if (response) {
      setPlayers(response.player);
    }
  };

  const handleTeamChange = (e) => {
    let id = 0;
    if (e.target.name !== "0") {
      id = +e.target.name;
    } else {
      id = 0;
    }
    team[id] = { ...team[id], team_id: e.target.value };
    setTeam([...team]);
  };

  const handleNummerChange = (e) => {
    let id = 0;
    if (e.target.id !== "0") {
      id = +e.target.id;
    } else {
      id = 0;
    }
    nummer[id] = { ...nummer[id], nummer: e.target.value };
    setNummer([...nummer]);
  };

  const handlePositionChange = (e) => {
    let id = 0;
    if (e.target.name !== "0") {
      id = +e.target.name;
    } else {
      id = 0;
    }
    position[id] = { ...position[id], position: e.target.value };
    setPosition([...position]);
  };

  const handleSave = async () => {
    const request = await POST("/spieler/save", {
      id: player.id,
      vorname: vorname,
      nachname: nachname,
      team: team,
      nummer: nummer,
      position: position,
    });
    if (request) {
      if (request.error) {
        setError(request.error);
      } else {
        searchDB();
      }
    }
  };

  const addTeam = () => {
    if (team.length < teams.length) {
      position.push({ position: 0 });
      setPosition([...position]);
      team.push({ team_id: 0 });
      setTeam([...team]);
      nummer.push({ nummer: 0 });
      setNummer([...nummer]);
    }
  };

  const HandleRemoveTeam = (id) => {
    //setConfirmRemove(true);
    team.splice(id, 1);
    setTeam([...team]);
    position.splice(id, 1);
    setPosition([...position]);
    nummer.splice(id, 1);
    setNummer([...nummer]);
  };

  useEffect(() => {
    searchDB();
  }, [search]);

  useEffect(() => {
    if (player !== 0) {
      setNachname(player.nachname);
      setVorname(player.vorname);
      setNummer(player.nummer);
      setTeam(player.team);
      setPosition(player.position);
    }
  }, [player]);

  useEffect(() => {
    setPlayer(0);
    setNachname("");
    setVorname("");
    setNummer([{ nummer: 0 }]);
    setTeam([{ team_id: 0 }]);
    setPosition([{ position: 0 }]);
    setError("");
  }, [players]);
  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Spieler bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row className="text-center pb-2">
          <Col>
            <TextField
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              label="Spieler suchen"
              value={search}
            />
          </Col>
        </Row>
        {players.length > 0 && (
          <Row>
            <Col>zum editieren klicken</Col>
          </Row>
        )}
        {players.length > 0 &&
          players.map((player, id) => {
            return (
              <Row
                key={id}
                className="pb-2"
                style={{ cursor: "pointer" }}
                onClick={() => setPlayer(player)}
              >
                <Col>
                  <u>{player.vorname}</u>
                </Col>
                <Col>
                  <u>{player.nachname}</u>
                </Col>
              </Row>
            );
          })}
        <Row className="text-center">
          <Col>
            <TextField
              disabled={player === 0}
              size="small"
              type="text"
              label="Vorname"
              onChange={(e) => setVorname(e.target.value)}
              value={vorname ?? ""}
            />
          </Col>
          <Col>
            <TextField
              disabled={player === 0}
              size="small"
              type="text"
              label="Nachname"
              onChange={(e) => setNachname(e.target.value)}
              value={nachname ?? ""}
            />
          </Col>
        </Row>
        <br />
        <Row className="text-center">
          <div className="mb-2">
            Spieler diese Saison einem Team zuweisen{" "}
            {player !== 0 && (
              <ControlPointIcon
                fontSize="sm"
                onClick={addTeam}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
          {player !== 0 &&
            team.map((team_temp, id) => {
              return (
                <Row key={id} className="mb-2">
                  <Col xs="auto">
                    <RemoveCircleOutlineIcon
                      fontSize="sm"
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleRemoveTeam(id)}
                    />
                  </Col>
                  <Col>
                    <FormControl disabled={player === 0} size="small">
                      <InputLabel>Team</InputLabel>
                      <Select
                        name={`${id}`}
                        label="Team"
                        value={team[id].team_id ?? 0}
                        onChange={(e) => handleTeamChange(e)}
                      >
                        <MenuItem value={0}>wählen</MenuItem>
                        {teams.map((team) => (
                          <MenuItem key={team.id} value={team.id}>
                            {team.short}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl size="small" sx={{ minWidth: "100px" }}>
                      <InputLabel>Position</InputLabel>
                      <Select
                        name={`${id}`}
                        label="Position"
                        value={position[id].position ?? 0}
                        onChange={(e) => handlePositionChange(e)}
                      >
                        <MenuItem value={0}>wählen</MenuItem>
                        <MenuItem value={1}>Tor</MenuItem>
                        <MenuItem value={2}>Verteidiger</MenuItem>
                        <MenuItem value={3}>Stürmer</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col>
                    <TextField
                      size="small"
                      type="number"
                      label="Nummer"
                      id={`${id}`}
                      onChange={(e) => handleNummerChange(e)}
                      value={nummer[id].nummer ?? 0}
                    />
                  </Col>
                </Row>
              );
            })}
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        {error !== "" && <span className="text-danger">{error}</span>}
        <Button
          size="sm"
          variant="outline-success"
          disabled={player === 0}
          onClick={handleSave}
        >
          speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlayerEditModal;
