import { combineReducers } from "redux";
import teams from "./reducers/teams";
import SpielPlan from "./reducers/spielplan";
import Stadien from "./reducers/stadien";
import Strafen from "./reducers/strafen";
import Loadings from "./reducers/loadings";
import Stats from "./reducers/stats";

export default combineReducers({
  teams,
  SpielPlan,
  Stadien,
  Strafen,
  Loadings,
  Stats,
});
