import React, { useState, useEffect } from "react";

const TeamLogo = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className={props.scorer === true ? "d-inline" : undefined}>
      <img
        alt="Team-Logo"
        width={
          props.detail === true
            ? "90px"
            : props.scorer === true
            ? "22px"
            : !isMobile
            ? "55px"
            : "50px"
        }
        height={
          props.detail === true
            ? "90px"
            : props.scorer === true
            ? "22px"
            : !isMobile
            ? "55px"
            : "50px"
        }
        src={require(`../../images/Logos/${props.team}.png`)}
      />
    </div>
  );
};

export default TeamLogo;
