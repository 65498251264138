import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../tools/fetch";
import Header from "./Header";
import LastGames from "./lastGames";
import NextGames from "./nextGames";
import Scorer from "./Scorer";
import TabelleBanner from "./TabelleBanner";
import { Backdrop, CircularProgress } from "@mui/material";

const Home = () => {
  const [loading, setLoading] = useState();
  const [tabelle, setTabelle] = useState();
  const SpielplanIsLoaded = useSelector((state) => state.Loadings.Spielplan);
  const TabelleIsLoaded = useSelector((state) => state.Loadings.Tabelle);
  const TabelleStore = useSelector((state) => state.teams.teams);
  const dispatch = useDispatch();

  const load = async () => {
    try {
      setLoading(true);
      if (!TabelleIsLoaded) {
        const load = await GET("/spiel/tabelle");
        setTabelle(load.tabelle);
        dispatch({ type: "ADD_TABELLE", payload: load.tabelle });
        dispatch({ type: "SET_LOAD_TABELLE", payload: true });
      } else {
        setTabelle(TabelleStore);
      }
      if (!SpielplanIsLoaded) {
        const spielplan = await GET("/spiel/all");
        dispatch({ type: "SET_PLAN", payload: spielplan.games });
        dispatch({ type: "SET_SPIELPLAN", payload: true });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <Row>
        <Col xs="12" md="6">
          <NextGames />
        </Col>
        <Col xs="12" md="6">
          <LastGames />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="pb-2">
          <Header title="Tabelle" />
          <Row>
            {loading === false &&
              Object.values(tabelle).map((team, id) => {
                return <TabelleBanner key={team.id} id={id} team={team.id} />;
              })}
          </Row>
        </Col>
        <Col xs="12" md="6">
          <Scorer />
        </Col>
      </Row>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
