import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import PlayerModal from "../player/PlayerModal";
import { GET } from "../tools/fetch";
import Header from "./Header";
import TeamLogo from "../teams/Logo";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Scorer = () => {
  const [player, setPlayer] = useState(0);
  const [loading, setLoading] = useState();
  const [sort, setSort] = useState("points");
  const [list, setList] = useState([]);
  const [direction, setDirection] = useState("desc");
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const HRStats = useSelector((state) => state.Stats.HRStats);
  const HRStatsLoaded = false;
  const dispatch = useDispatch();

  const loadScorer = async () => {
    if (HRStatsLoaded === false) {
      setLoading(true);
      const request = await GET(
        `/stats/scorer?sort=${sort}&order=${direction}&typ=hr&season=3`
      );
      if (request) {
        setList(request.points);
        dispatch({ type: "SET_LOAD_HR", payload: true });
        dispatch({ type: "SET_STATS_HR", payload: request.points });
      }
    } else {
      setList(HRStats);
    }
    setLoading(false);
  };

  const getBack = (rank) => {
    if (rank === 1) {
      return {
        background:
          "linear-gradient(to right,rgba(255,215,0,0.75),transparent)",
      };
    } else if (rank === 2) {
      return {
        background:
          "linear-gradient(to right,rgba(192,192,192,0.75),transparent)",
      };
    } else if (rank === 3) {
      return {
        background:
          "linear-gradient(to right,rgba(150,116,68,0.75),transparent)",
      };
    }
  };

  useEffect(() => {
    loadScorer();
  }, []);

  return (
    <div>
      <Header title="Scorer" />
      <Table className="text-white" responsive size="sm">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Spieler</th>
            <th className="text-center">Pkt.</th>
            <th className="text-center">T</th>
            <th className="text-center">A</th>
            <th className="text-center">&#8709;</th>
          </tr>
        </thead>
        <tbody>
          {list.length !== 0 &&
            list.map((player, id) => {
              if (id < 10) {
                return (
                  <tr
                    key={id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPlayerModal(true);
                      setPlayer(player.player.id);
                    }}
                  >
                    <td className="text-center" style={getBack(player.rank)}>
                      {player.rank}
                    </td>
                    <td>
                      <TeamLogo team={player.team.team_id} scorer={true} />
                      {"    "}
                      {player.player.nachname}, {player.player.vorname}
                    </td>
                    <td className="text-center">{player.points}</td>
                    <td className="text-center">{player.goals}</td>
                    <td className="text-center">{player.assists}</td>
                    <td className="text-center">{player.pointsS}</td>
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
      {player !== 0 && (
        <PlayerModal
          show={showPlayerModal}
          hide={() => setShowPlayerModal(false)}
          player={player}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Scorer;
