import { TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const TeamModal = (props) => {
  const [name, setName] = useState("");
  const [short, setShort] = useState("");
  const [logo, setLogo] = useState(null);
  const imageInputRef = useRef();
  const [errorImage, setErrorImage] = useState("");
  const [erfolge, setErfolge] = useState("");
  const [color, setColor] = useState("#000000");

  const handleImageChange = (event) => {
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setLogo(null);
      setErrorImage("Bitte wähle eine geeignete Datei");
      return false;
    } else {
      setLogo(event.target.files[0]);
    }
  };

  const resetInput = () => {
    setName("");
    setShort("");
    setLogo(null);
    imageInputRef.current.value = "";
    setErfolge("");
    setColor("#000000");
  };

  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
      onExited={() => setLogo(null)}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Team anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row className="text-center">
          <Col>
            <TextField
              size="small"
              type="text"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Col>
          <Col>
            <TextField
              size="small"
              type="text"
              label="short"
              onChange={(e) => setShort(e.target.value)}
              value={short}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextField
              size="small"
              type="text"
              label="Erfolge"
              onChange={(e) => setErfolge(e.target.value)}
              value={erfolge}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Logo</Form.Label>
            <Form.Control
              ref={imageInputRef}
              onChange={handleImageChange}
              type="file"
            />
            <div className="text-danger">{errorImage}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="exampleColorInput">Teamfarbe</Form.Label>
            <Form.Control
              onChange={(e) => setColor(e.target.value)}
              type="color"
              id="exampleColorInput"
              value={color}
              title="Teamfarbe"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button size="sm" onClick={resetInput} variant="outline-danger">
          Eingaben löschen
        </Button>
        <Button
          disabled={logo === null || name === null || short === null}
          size="sm"
          variant="outline-success"
        >
          anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamModal;
