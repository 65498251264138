import React, { useEffect, useState } from "react";

const Kontakt = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div>
      <div className="bg-secondary text-white text-center pt-3 pb-0">
        {!isMobile && (
          <a
            className="text-white"
            href="https://www.instagram.com/ghl_oberland/"
            target="_blank"
          >
            <img
              src={require("../../images/Sonstiges/insta.png")}
              style={{ width: "50px" }}
            />
            <br />
            Schaut bei uns auf Instagram vorbei!
          </a>
        )}
        {isMobile && (
          <a
            className="text-white"
            href="instagram://user?username=ghl_oberland"
          >
            <img
              src={require("../../images/Sonstiges/insta.png")}
              style={{ width: "50px" }}
            />
            <br />
            Schaut bei uns auf Instagram vorbei!
          </a>
        )}
      </div>
    </div>
  );
};

export default Kontakt;
