import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GET, POST } from "../tools/fetch";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";

const GameEditModalPlayoff = (props) => {
  const [game, setGame] = useState(0);
  const [games, setGames] = useState([]);
  const [home, setHome] = useState(0);
  const [away, setAway] = useState(0);
  const [clock, setClock] = useState("00:00");
  const [score_home, setScoreHome] = useState(0);
  const [score_away, setScoreAway] = useState(0);
  const teams = useSelector((state) => state.teams.teams);

  const getTeam = (id) => {
    const team = teams.find((team) => team.id === +id);
    return team ? team.short : "";
  };

  const getGames = async () => {
    const request = await GET("/spiel/all?playoff=1");
    if (request) {
      setGames(request.games);
    }
  };

  const saveGame = async () => {
    const request = await POST("/spiel/save", {
      id: game.id,
      home: home,
      away: away,
      time: clock,
      playoff: 1,
      score_home: +score_home,
      score_away: +score_away,
    });
  };

  const handleTimeChange = (e) => {
    setClock(e.target.value);
  };

  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (game) {
      const time_temp = moment(game.time).utc();
      setClock(time_temp.format("HH:mm"));
      setHome(game.home_team.id);
      setAway(game.away_team.id);
      setScoreAway(game.score_away);
      setScoreHome(game.score_home);
    }
  }, [game]);

  return (
    <Modal
      backdrop="static"
      className="text-white"
      centered
      size="xl"
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Spiel bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <>
          <FormControl size="small">
            <InputLabel>Spiel</InputLabel>
            <Select
              label="Team"
              value={game}
              onChange={(e) => setGame(e.target.value)}
            >
              <MenuItem value={0}>bitte wählen</MenuItem>
              {games.map((game, id) => {
                const date_temp = moment(game.date).utc();
                return (
                  <MenuItem key={id} value={game}>
                    {game.home_team.short} - {game.away_team.short}{" "}
                    {date_temp.format("DD.MM.YYYY")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Row className="mt-2">
            <Col>
              <TextField
                disabled
                size="small"
                label="Datum"
                type="date"
                value={game.date ?? 0}
              />
            </Col>
            <Col>
              <TextField
                disabled={game === 0}
                size="small"
                label="Zeit"
                type="time"
                value={clock ?? 0}
                onChange={(e) => handleTimeChange(e)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormControl disabled={game === 0} size="small">
                <InputLabel>Heim</InputLabel>
                <Select
                  label="Heim"
                  value={home ?? 0}
                  onChange={(e) => setHome(e.target.value)}
                >
                  <MenuItem value={0}>bitte wählen</MenuItem>
                  {teams.map((team) => (
                    <MenuItem
                      key={team.id}
                      disabled={away === team.id}
                      value={team.id}
                    >
                      {team.short}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col className="h4">vs.</Col>
            <Col>
              <FormControl disabled={game === 0} size="small">
                <InputLabel>Gast</InputLabel>
                <Select
                  label="Gast"
                  value={away ?? 0}
                  onChange={(e) => setAway(e.target.value)}
                >
                  <MenuItem value={0}>bitte wählen</MenuItem>
                  {teams.map((team) => (
                    <MenuItem
                      key={team.id}
                      disabled={home === team.id}
                      value={team.id}
                    >
                      {team.short}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormControl size="small">
                <TextField
                  size="small"
                  value={score_home}
                  onChange={(e) => setScoreHome(e.target.value)}
                  typeof="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl size="small">
                <TextField
                  size="small"
                  value={score_away}
                  onChange={(e) => setScoreAway(e.target.value)}
                  typeof="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </FormControl>
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button
          size="sm"
          variant="outline-success"
          disabled={game === 0}
          onClick={saveGame}
        >
          speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GameEditModalPlayoff;
