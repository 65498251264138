import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import TeamLogo from "../teams/Logo";
import Team from "../teams/Team";
import Header from "./Header";

const NextGames = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const now = new Date();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const nowM = moment();
  const isMobile = width <= 768;

  const games = useSelector((state) => state.SpielPlan.games);

  let max = 5;
  if (isMobile) {
    max = 3;
  }
  let round = 0;
  return (
    <div>
      <Header title="Nächsten Spiele" />
      {Object.values(games).map((game, index) => {
        const time = new Date(game.time);
        const date = new Date(game.date);

        const dateM = moment(game.date);

        if (round < max && nowM <= dateM) {
          round++;
          if (game.playoff !== 1)
            return (
              <Row key={game.id} className="text-center align-items-center">
                <Col className="g-0 gx-xl-4" xs="2">
                  <TeamLogo team={game.home_team.id} />
                </Col>
                <Col className="g-0">
                  <Team team={game.home_team} />
                </Col>
                <Col
                  className="g-0 gx-xl-4"
                  md={{ span: "2", order: "1" }}
                  xs={
                    game.bericht !== 1
                      ? { span: "12", order: "5" }
                      : { span: "2" }
                  }
                >
                  {game.bericht === 1 && (
                    <div>
                      <b>
                        <u>
                          {game.score_home}
                          {" : "}
                          {game.score_away}
                        </u>
                      </b>
                    </div>
                  )}
                  {!isMobile && (
                    <span style={{ fontSize: "calc(11px + 0.50625vw)" }}>
                      {!isMobile && game.bericht !== 1 && <div>vs.</div>}
                      <div className="small">
                        {now.toDateString() === date.toDateString()
                          ? "Heute"
                          : date.toLocaleDateString("de-DE", {
                              dateStyle: "short",
                            })}
                        ,{" "}
                        {time.toLocaleTimeString("de-DE", {
                          timeZone: "UTC",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        Uhr
                      </div>
                    </span>
                  )}
                </Col>
                {isMobile && (
                  <Col
                    style={{ fontSize: "calc(11px + 0.50625vw)" }}
                    className="g-0 gx-xl-4"
                    md={{ span: "2", order: "1" }}
                    xs={{ span: "12", order: "5" }}
                  >
                    <div className="small">
                      {now.toDateString() === date.toDateString()
                        ? "Heute"
                        : date.toLocaleDateString("de-DE", {
                            dateStyle: "short",
                          })}
                      ,{" "}
                      {time.toLocaleTimeString("de-DE", {
                        timeZone: "UTC",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      Uhr
                    </div>
                  </Col>
                )}
                <Col className="g-0" md={{ order: "2" }}>
                  <Team team={game.away_team} />
                </Col>
                <Col className="g-0 gx-xl-4" md={{ order: "3" }} xs="2">
                  <TeamLogo team={game.away_team.id} />
                </Col>
              </Row>
            );
        }
      })}
    </div>
  );
};

export default NextGames;
