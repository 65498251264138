import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { POST } from "../tools/fetch";
import { useSelector } from "react-redux";

const GameModal = (props) => {
  const [date, setDate] = useState("0000-00-00");
  const [clock, setClock] = useState("00:00");
  const [home, setHome] = useState(1);
  const [away, setAway] = useState(1);
  const [stadion, setStadion] = useState(1);
  const teams = useSelector((state) => state.teams.teams);

  const handleDateChange = (newDate) => {
    setDate(newDate.target.value);
  };

  const handleTimeChange = (e) => {
    setClock(e.target.value);
  };

  const resetInput = () => {
    setDate("0000-00-00");
    setClock("00:00");
    setHome(1);
    setAway(1);
  };

  const send = async () => {
    try {
      const post = await POST("/spiel/add", {
        home: home,
        away: away,
        ort: stadion,
        date: date,
        time: clock,
      });
    } catch (error) {
    } finally {
      setDate("0000-00-00");
      setClock("00:00");
      setHome(0);
      setAway(0);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        className="text-white"
        centered
        show={props.show}
        onHide={props.hide}
      >
        <Modal.Header
          style={{ backgroundColor: "var(--bs-gray-500)" }}
          closeButton
        >
          <Modal.Title>Spiel anlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
          <Row className="text-center">
            <Col>
              <TextField
                size="small"
                label="Datum"
                type="date"
                value={date}
                onChange={(newDate) => handleDateChange(newDate)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                label="Zeit"
                type="time"
                value={clock}
                onChange={(e) => handleTimeChange(e)}
              />
            </Col>
          </Row>
          <br />
          <Row className="text-center">
            <Col>
              <FormControl size="small">
                <InputLabel>Heim</InputLabel>
                <Select
                  label="Heim"
                  value={home}
                  onChange={(e) => setHome(e.target.value)}
                >
                  {teams.map((team) => (
                    <MenuItem
                      disabled={away === team.id}
                      key={team.id}
                      value={team.id}
                    >
                      {team.short}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs="auto" className="h4">
              vs.
            </Col>
            <Col>
              <FormControl size="small">
                <InputLabel>Gast</InputLabel>
                <Select
                  label="Gast"
                  value={away}
                  onChange={(e) => setAway(e.target.value)}
                >
                  {teams.map((team) => (
                    <MenuItem
                      disabled={home === team.id}
                      key={team.id}
                      value={team.id}
                    >
                      {team.short}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <FormControl disabled size="small">
                <InputLabel>Ort</InputLabel>
                <Select
                  label="Ort"
                  value={stadion}
                  onChange={(e) => setStadion(e.target.value)}
                >
                  <MenuItem value={1}>Peißenberg</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
          <Button size="sm" onClick={resetInput} variant="outline-danger">
            Eingaben löschen
          </Button>
          <Button
            onClick={send}
            size="sm"
            variant="outline-success"
            disabled={
              home === 0 ||
              away === 0 ||
              clock === "00:00" ||
              date === "0000-00-00"
            }
          >
            anlegen
          </Button>
        </Modal.Footer>
      </Modal>
    </LocalizationProvider>
  );
};

export default GameModal;
