import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  return (
    <Row className="text-center justify-content-center">
      <Col
        className="ps-5 pe-5 pt-2 pb-2 rounded"
        style={{
          cursor: "pointer",
          background: "rgba(255,255,255,0.3)",
        }}
        xs="auto"
        onClick={() => {
          if (props.title !== "Kader" && props.title !== "Statistik") {
            navigate(
              `${
                props.title === "Nächsten Spiele" ||
                props.title === "Letzten Spiele"
                  ? "/spielplan"
                  : `/${props.title}`
              }`
            );
          }
        }}
      >
        {props.title}
      </Col>
    </Row>
  );
};

export default Header;
