import React from "react";
import { Accordion, Container } from "react-bootstrap";

const Rules = () => {
  return (
    <Container className="p-0" style={{ textAlign: "justify" }}>
      <Accordion alwaysOpen>
        <Accordion.Item
          className="text-white"
          eventKey="0"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <Accordion.Button
            className="text-white"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <b>Allgemein</b>
          </Accordion.Button>
          <Accordion.Body>
            <ul className="pt-1 ps-2">
              <li>
                Die Heimmannschaft ist für die Bereitstellung des
                Schriedsrichters verantwortlich.
                <br />
                Dieser muss regelkundig und volljährig sein.
              </li>
              <li>
                Die Spielzeit eines Spiels beträgt 2 x 30 Minuten durchlaufend
                ohne Auszeit mit einer Halbzeitpause von 5 Minuten.
              </li>
              <li>
                Punktevergabe:
                <br />2 Punkte für einen Sieg
                <br />1 Punkt bei Unentschieden für beide Mannschaften
              </li>
              <li>
                Ein Spiel muss mindestens 48 Stunden vor Spielbeginn abgesagt
                bzw. verlegt werden.
                <br />
                Ausnahmen gelten nur bei höherer Gewalt.
                <br />
                Ein Ersatztermin muss innerhalb von zwei Wochen gefunden werden.
                <br />
                Bei nicht Antreten einer Mannschaft, gilt das Spiel für sie als
                verloren (0:5 Niederlage).
              </li>
              <li>
                Der Spielbericht ist bis spätestens zum nächsten Tag,
                vollständig und leserlich ausgefüllt an die Ligen-Leitung zu
                übersenden. z. B. Foto in Whatsapp.
              </li>
              <li>
                Startgebühr für die Liga beträgt aktuell 350,- € pro Mannschaft,
                diese ist bis zum 01.10. der jeweiligen Saison, auf das
                GHL-Konto zu überweisen.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          className="text-white"
          eventKey="4"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <Accordion.Button
            className="text-white"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <b>Modus</b>
          </Accordion.Button>
          <Accordion.Body>
            <ul className="pt-1 ps-2">
              <li>Hauptrunde</li>
              <ul>
                <li>Der Liga-Start ist jeweils am 1. Oktober.</li>
                <li>
                  Die Hauptrundenspiele sind bis Ende Februar durchzuführen
                </li>
                <li>
                  jeweils ein Hin- und Rückspiel gegen alle anderen
                  Mannschaften.
                </li>
                <li>Es wird laufend eine Tabelle gepflegt.</li>
                <li>
                  Bei punktgleichen Mannschaften entscheidet zuerst das
                  Torverhältnis, dann der direkte Vergleich über die
                  Platzierung.
                </li>
                <li>
                  Nach dem Ende der Hauptrunde gibt es ein Playoff-Turnier.
                </li>
              </ul>
              <li>Playoff-Turnier</li>
              <ul>
                <li>Das Turnier wird an einem Tag gespielt.</li>
                <li>Das Playoff-Turnier findet im März statt.</li>
                <li>
                  Der Modus des Turniers ist abhängig von der Anzahl der aktiven
                  Mannschaften der Liga.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          className="text-white"
          eventKey="2"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <Accordion.Button
            className="text-white"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <b>Spielberechtigung</b>
          </Accordion.Button>
          <Accordion.Body>
            <ul className="pt-1 ps-2">
              <li>
                Ehemalige Vereins-Spieler sind nur dann spielberechtigt, wenn
                sie im Nachwuchsbereich nicht höher als Bundesliga und im
                Seniorenbereich nicht höher als Bezirksliga gespielt haben.
                <br />
                Außerdem sind sie nur dann spielberechtigt, wenn seit dem
                letzten Verbandsspiel mindestens 12 Monate vergangen sind.
              </li>
              <li>
                Eine Ausnahme hiervon stellen Damen dar.
                <br />
                Sie sind mit Rücksprache der Ligen Leitung immer
                spielberechtigt.
              </li>
              <li>
                Maximalspielstärke pro Spiel beträgt für jede Mannschaft 20
                Feldspieler + 2 Torhüter.
                <br /> Pro Mannschaft dürfen maximal 25 Feldspieler + 4 Torhüter
                für den Spielbetrieb gemeldet werden.
              </li>
              <li>
                Ein Spieler ist erst dann für das Play-off-Turnier berechtigt,
                wenn er eine Mindest-Spielanzahl von 50% der Hauptrunde
                vorzuweisen hat. Ausgenommen von dieser Regel sind Torhüter.
              </li>
              <li>
                Spielberechtigt ist jeder Spieler, der auf einer Spielerliste
                steht, die bis zum 15.09. an die Ligen-Leitung abgegeben werden
                muss.
                <br />
                (Darauf enthalten sein muss: Name, Vorname, Trikotnummer,
                Geburtsdatum, Geburtsort, zuletzt aktiv, bei welchem Verein).
              </li>
              <li>
                Sollte ein Spieler eingesetzt werden, der nicht ordnungsgemäß
                angemeldet ist, wird das Spiel für die betreffende Mannschaft
                als verloren gewertet. ( 2 Punkte und 0:5 Tore)
              </li>
              <li>
                Nachmeldeschluss für Spieler ist der jeweilige 30. November.
              </li>
              <li>
                Spieler sind immer nur für eine Mannschaft der GHL,
                spielberechtigt.
                <br />
                Torhüter sind von dieser Regelung ausgenommen.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          className="text-white"
          eventKey="1"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <Accordion.Button
            className="text-white"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <b>Spielregeln</b>
          </Accordion.Button>
          <Accordion.Body>
            <ul className="pt-1 ps-2">
              <li>Die Spiele finden unter den Regeln des DEB statt.</li>
              <li>
                Das Regelwerk des DEB findet volle Anwendung. Ausgenommen sind:
                <ul>
                  <li>
                    2 Min. Spielverzögerung bei Schuss des Pucks über die Bande.
                  </li>
                  <li>Wechselverbot bei Icing.</li>
                  <li>Hybrid-Icing.</li>
                  <li>Seitenwahl des Bullys bei Icing oder Strafe.</li>
                </ul>
              </li>
              <li>
                Strafen:
                <ul>
                  <li>Strafzeit von 2 Minuten {"->"} 2€</li>
                  <li>Strafzeit von 5 Minuten {"->"} 5€</li>
                  <li>Strafzeit von 10 Minuten {"->"} 10€</li>
                  <li>Strafzeit von 20 Minuten {"->"} 20€</li>
                </ul>
              </li>
              <li>
                Erhält ein Spieler eine Spieldauer-Strafe, ist dieser für das
                nächste Liga-Spiel gesperrt.
              </li>
              <li>
                Erhält ein Spieler in einer Saison dreimal eine 10-minütige
                Disziplinarstrafe, ist dieser für das nächste Ligaspiel
                gesperrt.
              </li>
              <li>
                Erhält ein Spieler in einer Saison dreimal eine
                Spieldauer-Strafe, ist der Spieler für die komplette Saison
                inklusive Playoff-Turnier gesperrt.
              </li>
              <li>
                Wird ein gesperrter Spieler in einem Liga-Spiel eingesetzt, wird
                das Spiel gegen die sich verfehlende Mannschaft gewertet.
              </li>
              <li>
                Das Strafgeld ist von jeder Mannschaft über die Saison
                einzusammeln und nach der Rückrunde auf einmal zu zahlen.
              </li>
              <li>
                Das Geld, das für die Strafen eingesammelt wird, wird zur
                Finanzierung des Playoff-Turniers verwendet.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          className="text-white"
          eventKey="3"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <Accordion.Button
            className="text-white"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <b>Haftung</b>
          </Accordion.Button>
          <Accordion.Body>
            <ul className="pt-1 ps-2">
              <li>
                Durch die Zahlung des Unkostenbeitrags erkennt jede Mannschaft
                alle DEB-Richtlinien inkl. Bestimmungen der GHL an.
              </li>
              <li>
                Die Teilnahme an der GHL erfolgt auf eigene Gefahr. Eine Haftung
                des Veranstalters ist ausdrücklich ausgeschlossen.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default Rules;
