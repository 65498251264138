import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TeamLogo from "../teams/Logo";
import Team from "../teams/Team";
import Header from "./Header";

const LastGames = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const games = useSelector((state) => state.SpielPlan.games);
  // Lokaler State für den sortierten Array
  const [sortedGames, setSortedGames] = useState([]);
  const navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    if (Array.isArray(games)) {
      // Kopie des Arrays erstellen
      const sortedArray = [...games];

      // Sortieren der Kopie nach dem Datum
      sortedArray.sort((a, b) => new Date(b.date) - new Date(a.date));
      setSortedGames(sortedArray);
    }
  }, [games]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  let max = 5;
  if (isMobile) {
    max = 3;
  }
  const now = new Date();
  let round = 0;
  return (
    <div>
      <Header title="Letzten Spiele" />
      <div>
        {Object.values(sortedGames).map((game, index) => {
          const time = new Date(game.time);
          const date = new Date(game.date);

          const nowM = moment();
          const dateM = moment(game.date);

          if (
            game.bericht === 1 &&
            dateM < nowM &&
            round < max &&
            game.playoff !== 1
          ) {
            round++;
            return (
              <Row key={game.id} className="text-center align-items-center">
                <Col className="g-0 gx-xl-4" xs="2">
                  <TeamLogo team={game.home_team.id} />
                </Col>
                <Col className="g-0">
                  <Team team={game.home_team} />
                </Col>
                <Col
                  className="g-0 gx-xl-4"
                  md={{ span: "2", order: "1" }}
                  xs={
                    game.bericht !== 1
                      ? { span: "12", order: "5" }
                      : { span: "2" }
                  }
                >
                  {game.bericht === 1 && (
                    <div
                      onClick={() => navigate(`/game/${game.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <b>
                        <u>
                          {game.score_home}
                          {" : "}
                          {game.score_away}
                        </u>
                      </b>
                    </div>
                  )}
                  {!isMobile && (
                    <span style={{ fontSize: "calc(11px + 0.50625vw)" }}>
                      {!isMobile && game.bericht !== 1 && <div>vs.</div>}
                      <div className="small">
                        {date.toLocaleDateString("de-DE", {
                          dateStyle: "short",
                        })}
                        ,{" "}
                        {time.toLocaleTimeString("de-DE", {
                          timeZone: "UTC",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        Uhr
                      </div>
                    </span>
                  )}
                </Col>
                {isMobile && (
                  <Col
                    style={{ fontSize: "calc(11px + 0.50625vw)" }}
                    className="g-0 gx-xl-4"
                    md={{ span: "2", order: "1" }}
                    xs={{ span: "12", order: "5" }}
                  >
                    <div className="small">
                      {date.toLocaleDateString("de-DE", {
                        dateStyle: "short",
                      })}
                      ,{" "}
                      {time.toLocaleTimeString("de-DE", {
                        timeZone: "UTC",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      Uhr
                    </div>
                  </Col>
                )}
                <Col className="g-0" md={{ order: "2" }}>
                  <Team team={game.away_team} />
                </Col>
                <Col className="g-0 gx-xl-4" md={{ order: "3" }} xs="2">
                  <TeamLogo team={game.away_team.id} />
                </Col>
              </Row>
            );
          }
        })}
      </div>
    </div>
  );
};

export default LastGames;
