import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const SeasonEditModal = (props) => {
  const [season, setSeason] = useState(0);
  const [beg, setBeg] = useState("0000-00-00");
  const [end, setEnd] = useState("0000-00-00");

  const handleDateChange = (e) => {
    if (e.target.name === "beg") {
      setBeg(e.target.value);
    } else {
      setEnd(e.target.value);
    }
  };

  const resetInput = () => {};
  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Saison anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row className="text-center">
          <Col>
            <FormControl sx={{ minWidth: "100px" }} size="small">
              <InputLabel>Season</InputLabel>
              <Select
                label="Season"
                value={season}
                onChange={(e) => setSeason(e.target.value)}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="mt-2 text-center">
          <Col>
            <TextField
              disabled={season === 0}
              name="beg"
              size="small"
              label="Beginn"
              type="date"
              value={beg}
              onChange={(e) => handleDateChange(e)}
            />
          </Col>
          <Col>
            <TextField
              disabled={season === 0}
              name="end"
              size="small"
              label="Ende"
              type="date"
              value={end}
              onChange={(e) => handleDateChange(e)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button
          disabled={season === 0}
          size="sm"
          onClick={resetInput}
          variant="outline-danger"
        >
          Eingaben löschen
        </Button>
        <Button
          disabled={
            season !== 0 && (beg === "0000-00-00" || end === "0000-00-00")
          }
          size="sm"
          variant="outline-success"
        >
          anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SeasonEditModal;
