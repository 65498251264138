import { Button } from "react-bootstrap";
import React, { useState } from "react";
import Gallery from "./gallery";

const GalleryIndex = () => {
  const [activeAlbum, setActiveAlbum] = useState(1);

  return (
    <div>
      <Button
        variant={activeAlbum === 1 ? "secondary" : "outline-secondary"}
        className="m-2 text-white"
        onClick={() => setActiveAlbum(1)}
      >
        Playoff-Turnier 2023
      </Button>
      {
        <Button
          variant={activeAlbum === 2 ? "secondary" : "outline-secondary"}
          className="text-white"
          onClick={() => setActiveAlbum(2)}
        >
          Playoff-Turnier 2024
        </Button>
      }
      <Gallery album={activeAlbum} />
    </div>
  );
};

export default GalleryIndex;
