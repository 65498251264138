import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import TeamLogo from "../teams/Logo";
import { GET } from "../tools/fetch";
import Awards from "../teams/KaderPlayerAwards";

const PlayerModal = (props) => {
  const [loading, setLoading] = useState();
  const [player, setPlayer] = useState([]);
  const [typ, setTyp] = useState("hr");
  const teams = useSelector((state) => state.teams.teams);

  const loadPlayer = async () => {
    try {
      setLoading(true);
      const request = await GET(`/spieler/get/${props.player}`);
      if (request) {
        setPlayer(request.player);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getTeam = (id) => {
    const team = teams.find((team) => team.id === +id);
    return team ? team.name : "";
  };

  const getPosition = (id) => {
    switch (id) {
      case 1:
        return "Torhüter";
      case 2:
        return "Verteidiger";
      case 3:
        return "Stürmer";
      default:
        break;
    }
  };

  useEffect(() => {
    loadPlayer();
  }, [props.player]);

  return (
    loading === false && (
      <Modal
        className="text-white"
        scrollable
        show={props.show}
        onHide={props.hide}
        centered
      >
        <Modal.Header style={{ backgroundColor: "var(--bs-gray-500)" }}>
          <Row>
            <Col xs="auto">
              <TeamLogo detail team={props.team ?? player.team.team_id} />
            </Col>
            <Col className="d-grid align-items-center" xs="auto">
              <div className="h5 justify-content-between d-flex">
                <div>
                  #{player.team.nummer} {player.vorname} {player.nachname}
                </div>
                <div className="d-flex">
                  <Awards awards={player.awards} />
                </div>
              </div>
              <div className="small">
                {getTeam(props.team ?? player.team.team_id)}
              </div>
              <div className="small">{getPosition(player.team.position)}</div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body
          className="pt-2"
          style={{ backgroundColor: "var(--bs-gray-500)" }}
        >
          <div className="d-flex justify-content-evenly">
            <Button
              className="text-white"
              variant={typ === "hr" ? "secondary" : "outline-secondary"}
              onClick={() => setTyp("hr")}
            >
              Hauptrunde
            </Button>
            <Button
              className="text-white"
              variant={typ === "po" ? "secondary" : "outline-secondary"}
              onClick={() => setTyp("po")}
            >
              Playoffs
            </Button>
          </div>
          <Row>
            <Table className="text-white" size="sm">
              <thead>
                <tr>
                  <th>Saison</th>
                  <th>Team</th>
                  <th>Sp.</th>
                  <th>P</th>
                  <th>T</th>
                  <th>A</th>
                  <th>Str.</th>
                </tr>
              </thead>
              <tbody>
                {typ === "hr" &&
                  player.seasons.map((player_season, id) => {
                    return (
                      <tr key={id}>
                        <td>{player_season.season.short}</td>
                        <td>{player_season.team.name}</td>
                        <td>{player_season.games}</td>
                        <td>{player_season.points}</td>
                        <td>{player_season.goals}</td>
                        <td>{player_season.assists}</td>
                        <td>{player_season.strafen}</td>
                      </tr>
                    );
                  })}
                {typ === "po" &&
                  player.playoffs.map((player_season, id) => {
                    return (
                      <tr key={id}>
                        <td>{player_season.season.short}</td>
                        <td>{player_season.team.name}</td>
                        <td>{player_season.games}</td>
                        <td>{player_season.points}</td>
                        <td>{player_season.goals}</td>
                        <td>{player_season.assists}</td>
                        <td>{player_season.strafen}</td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td>Summe</td>
                  <td> </td>
                  <td>{typ === "hr" ? player.games : player.gamesP}</td>
                  <td>{typ === "hr" ? player.points : player.pointsP}</td>
                  <td>{typ === "hr" ? player.goals : player.goalsP}</td>
                  <td>{typ === "hr" ? player.assists : player.assistsP}</td>
                  <td>{typ === "hr" ? player.strafen : player.strafenP}</td>
                </tr>
              </tfoot>
            </Table>
          </Row>
        </Modal.Body>
      </Modal>
    )
  );
};

export default PlayerModal;
