import { useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const Gallery = (props) => {
  const [images, setImages] = useState([]);
  function importAll(r) {
    let images_temp = [];
    r.keys().forEach((item, index) => {
      images_temp[index] = { original: r(item) };
    });
    return images_temp;
  }
  useEffect(() => {
    switch (props.album) {
      case 1:
        setImages(
          importAll(
            require.context(
              "../../images/Gallerie/playoff_2023",
              false,
              /\.(png|jpe?g|svg)$/
            )
          )
        );
        break;
      case 2:
        setImages(
          importAll(
            require.context(
              "../../images/Gallerie/playoff_2024",
              false,
              /\.(png|jpe?g|svg)$/
            )
          )
        );
        break;
      default:
        break;
    }
  }, [props]);

  return (
    <div>
      {images.length !== 0 && <ReactImageGallery lazyLoad items={images} />}
    </div>
  );
};

export default Gallery;
