import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Team = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const teams_store = useSelector((state) => state.teams.teams);
  const team_t = teams_store.filter((team) => {
    if (team.id === props.team.id) return team;
  });

  const navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div
      onClick={() => navigate(`/team/${props.team.id}`)}
      style={{ cursor: "pointer", fontSize: "calc(10px + 0.50625vw)" }}
    >
      {props.team.name}{" "}
      <span style={{ fontSize: "calc((10px + 0.50625vw) * 0.9)" }}>
        {isMobile && <br />}
        {props.team.id !== 0 && `(${team_t[0].place})`}
      </span>
    </div>
  );
};

export default Team;
