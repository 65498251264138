import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GET, POST } from "../tools/fetch";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const GameEditModal = (props) => {
  const [game, setGame] = useState(0);
  const [games, setGames] = useState([]);
  const [date, setDate] = useState("0000-00-00");
  const [clock, setClock] = useState("00:00");
  const [home, setHome] = useState(0);
  const [homeKader, setHomeKader] = useState();
  const [away, setAway] = useState(0);
  const [awayKader, setAwayKader] = useState();
  const [stadion, setStadion] = useState(1);
  const [edit, setEdit] = useState(0);
  const [tore, setTore] = useState([
    { goal: 0, assist: 0, art: 1, team_id: 0 },
  ]);
  const [strafen, setStrafen] = useState([
    { player: 0, art: 0, team_id: 0, time: 0 },
  ]);
  const teams = useSelector((state) => state.teams.teams);
  const stadien = useSelector((state) => state.Stadien.stadien);
  const strafenStore = useSelector((state) => state.Strafen);

  const handleDateChange = (newDate) => {
    setDate(newDate.target.value);
  };

  const handleTimeChange = (e) => {
    setClock(e.target.value);
  };

  const getGames = async () => {
    const request = await GET("/spiel/all");
    if (request) {
      setGames(request.games);
    }
  };

  const saveGame = async () => {
    const request = await POST("/spiel/save", {
      id: game.id,
      home: home,
      away: away,
      time: clock,
      date: date,
    });
  };

  const getKader = async () => {
    const request = await GET(
      `/team/kaderBericht?team=${home}&game=${game.id}`
    );
    if (request) {
      setHomeKader(request.kader);
    }
    const request2 = await GET(
      `/team/kaderBericht?team=${away}&game=${game.id}`
    );
    if (request2) {
      setAwayKader(request2.kader);
    }
  };

  const getTore = async () => {
    const request = await GET(`/spiel/getgoalsbericht?game=${game.id}`);
    if (request.tore.length > 0) {
      setTore(request.tore);
    }
  };

  const getStrafen = async () => {
    const request = await GET(`/spiel/getstrafenbericht?game=${game.id}`);
    if (request.strafen.length > 0) {
      setStrafen(request.strafen);
    }
  };

  const saveKader = async (side) => {
    let kader;
    if (side === "h") {
      kader = homeKader;
    } else {
      kader = awayKader;
    }
    console.log(kader);
    const request = await POST(`/spiel/savekader`, {
      kader: kader,
      game: game.id,
    });
  };

  const saveGoals = async () => {
    const request = await POST("/spiel/savegoals", {
      tore: tore,
      game: game.id,
    });
  };

  const saveStrafen = async () => {
    const request = await POST("/spiel/savestrafen", {
      strafen: strafen,
      game: game.id,
    });
  };

  const handleCheck = (player, id, side) => {
    if (side === "h") {
      homeKader[id].anwesend = !homeKader[id].anwesend;
      setHomeKader([...homeKader]);
    } else {
      awayKader[id].anwesend = !awayKader[id].anwesend;
      setAwayKader([...awayKader]);
    }
  };

  const handleChangeGoals = (e, id) => {
    //team bestimmen
    if (awayKader.find((player) => player.player_id === +e.value)) {
      tore[id]["team_id"] = away;
    } else {
      tore[id]["team_id"] = home;
    }

    tore[id][e.name] = e.value;
    setTore([...tore]);
  };

  const HandleRemoveGoal = (id) => {
    if (tore.length > 1) {
      tore.splice(id, 1);
      setTore([...tore]);
    }
  };

  const HandleRemoveStrafe = (id) => {
    if (strafen.length > 1) {
      strafen.splice(id, 1);
      setStrafen([...strafen]);
    }
  };

  const handleChangeStrafen = (e, id) => {
    if (e.name === "player") {
      //team bestimmen
      if (awayKader.find((player) => player.player_id === +e.value)) {
        strafen[id]["team_id"] = away;
      } else {
        strafen[id]["team_id"] = home;
      }
    }
    strafen[id][e.name] = e.value;
    setStrafen([...strafen]);
  };

  const getTeam = (id) => {
    const team = teams.find((team) => team.id === +id);
    return team ? team.short : "";
  };

  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (game) {
      const time_temp = moment(game.time).utc();
      const date_temp = moment(game.date).utc();
      setClock(time_temp.format("HH:mm"));
      setDate(date_temp.format("YYYY-MM-DD"));
      setHome(game.home_team.id);
      setAway(game.away_team.id);
    }
  }, [game]);

  useEffect(() => {
    if (edit === 1 || edit === 2) {
      getKader();
    } else if (edit === 3) {
      getKader();
      getTore();
    } else if (edit === 4) {
      getStrafen();
    }
  }, [edit]);

  useEffect(() => {
    if (tore[tore.length - 1].goal !== 0) {
      tore.push({ goal: 0, assist: 0, art: 1, team_id: 0 });
      setTore([...tore]);
    }
  }, [tore]);

  useEffect(() => {
    if (strafen[strafen.length - 1].player !== 0) {
      strafen.push({ player: 0, art: 0, team_id: 0, time: 0 });
      setStrafen([...strafen]);
    }
  }, [strafen]);

  return (
    <Modal
      backdrop="static"
      className="text-white"
      centered
      size="xl"
      show={props.show}
      onHide={props.hide}
      onExited={() => {
        setEdit(0);
        setDate("0000-00-00");
        setClock("00:00");
        setHome(0);
        setAway(0);
      }}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Spiel bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        {edit === 0 && (
          <>
            <FormControl size="small">
              <InputLabel>Spiel</InputLabel>
              <Select
                label="Team"
                value={game}
                onChange={(e) => setGame(e.target.value)}
              >
                <MenuItem value={0}>bitte wählen</MenuItem>
                {games.map((game, id) => {
                  const date_temp = moment(game.date).utc();
                  return (
                    <MenuItem key={id} value={game}>
                      {game.home_team.short} - {game.away_team.short}{" "}
                      {date_temp.format("DD.MM.YYYY")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Row className="mt-2">
              <Col>
                <TextField
                  disabled={game === 0}
                  size="small"
                  label="Datum"
                  type="date"
                  value={date ?? 0}
                  onChange={(newDate) => handleDateChange(newDate)}
                />
              </Col>
              <Col>
                <TextField
                  disabled={game === 0}
                  size="small"
                  label="Zeit"
                  type="time"
                  value={clock ?? 0}
                  onChange={(e) => handleTimeChange(e)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormControl disabled={game === 0} size="small">
                  <InputLabel>Heim</InputLabel>
                  <Select
                    label="Heim"
                    value={home ?? 0}
                    onChange={(e) => setHome(e.target.value)}
                  >
                    <MenuItem value={0}>bitte wählen</MenuItem>
                    {teams.map((team) => (
                      <MenuItem
                        key={team.id}
                        disabled={away === team.id}
                        value={team.id}
                      >
                        {team.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col className="h4">vs.</Col>
              <Col>
                <FormControl disabled={game === 0} size="small">
                  <InputLabel>Gast</InputLabel>
                  <Select
                    label="Gast"
                    value={away ?? 0}
                    onChange={(e) => setAway(e.target.value)}
                  >
                    <MenuItem value={0}>bitte wählen</MenuItem>
                    {teams.map((team) => (
                      <MenuItem
                        key={team.id}
                        disabled={home === team.id}
                        value={team.id}
                      >
                        {team.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormControl disabled size="small">
                  <InputLabel>Ort</InputLabel>
                  <Select
                    label="Ort"
                    value={stadion}
                    onChange={(e) => setStadion(e.target.value)}
                  >
                    {stadien.map((stadion) => (
                      <MenuItem key={stadion.id} value={stadion.id}>
                        {stadion.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Button
              disabled={game === 0}
              className="mt-2"
              variant="outline-primary"
              onClick={() => setEdit(1)}
            >
              Spielbericht eintragen
            </Button>
          </>
        )}
        {edit === 1 && (
          <>
            <div className="h3">Heim-Team</div>
            <Table size="sm" responsive striped>
              <thead>
                <tr>
                  <th>Anwesend</th>
                </tr>
              </thead>
              <tbody>
                {homeKader &&
                  homeKader.map((player, id) => {
                    return (
                      <tr key={id}>
                        <td>
                          <Form.Check
                            checked={player.anwesend}
                            onChange={() => handleCheck(player, id, "h")}
                            type="checkbox"
                            id={player.id}
                            label={`${player.playerDetails.nachname}, ${player.playerDetails.vorname}`}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="d-block text-end">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setEdit(2);
                  saveKader("h");
                }}
              >
                speichern & weiter
              </Button>
            </div>
          </>
        )}
        {edit === 2 && (
          <>
            <div className="h3">Auswärts-Team</div>
            <Table size="sm" responsive striped>
              <thead>
                <tr>
                  <th>Anwesend</th>
                </tr>
              </thead>
              <tbody>
                {awayKader &&
                  awayKader.map((player, id) => {
                    return (
                      <tr key={id}>
                        <td>
                          <Form.Check
                            checked={player.anwesend}
                            type="checkbox"
                            id={player.id}
                            label={`${player.playerDetails.nachname}, ${player.playerDetails.vorname}`}
                            onChange={() => handleCheck(player, id, "a")}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="d-block text-end">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setEdit(3);
                  saveKader("a");
                }}
              >
                speichern & weiter
              </Button>
            </div>
          </>
        )}
        {edit === 3 && (
          <>
            <div className="h3">Statistiken</div>
            <div className="h4">Tore</div>
            {tore.map((tor, id) => {
              return (
                <Row key={id} className="mb-2">
                  <Col xs="auto">
                    <RemoveCircleOutlineIcon
                      fontSize="sm"
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleRemoveGoal(id)}
                    />
                  </Col>
                  <Col xs="auto" className="h5 align-self-center">
                    {id + 1}.
                  </Col>
                  <Col xs="auto" className="ps-0">
                    <FormControl size="small">
                      <InputLabel>Tor</InputLabel>
                      <Select
                        label="Team"
                        value={tor.goal}
                        name="goal"
                        onChange={(e) => handleChangeGoals(e.target, id)}
                      >
                        <MenuItem value={0}>bitte wählen</MenuItem>
                        {homeKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem
                                key={id}
                                value={player.player_id}
                                disabled={
                                  awayKader.find(
                                    (player) => player.player_id === +tor.assist
                                  )
                                    ? true
                                    : +tor.assist === player.player_id
                                    ? true
                                    : false
                                }
                              >
                                {getTeam(player.team_id)}: #{player.nummer}{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                        {awayKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem
                                key={id}
                                value={player.player_id}
                                disabled={
                                  homeKader.find(
                                    (player) => player.player_id === +tor.assist
                                  )
                                    ? true
                                    : +tor.assist === player.player_id
                                    ? true
                                    : false
                                }
                              >
                                {getTeam(player.team_id)}: #{player.nummer}{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs="auto" className="ps-0">
                    <FormControl size="small">
                      <InputLabel>Assist</InputLabel>
                      <Select
                        label="Team"
                        value={tor.assist}
                        name="assist"
                        onChange={(e) => handleChangeGoals(e.target, id)}
                      >
                        <MenuItem value={0}>bitte wählen</MenuItem>
                        {homeKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem
                                key={id}
                                value={player.player_id}
                                disabled={
                                  awayKader.find(
                                    (player) => player.player_id === +tor.goal
                                  )
                                    ? true
                                    : +tor.goal === player.player_id
                                    ? true
                                    : false
                                }
                              >
                                {getTeam(player.team_id)}: #{player.nummer}{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                        {awayKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem
                                key={id}
                                value={player.player_id}
                                disabled={
                                  homeKader.find(
                                    (player) => player.player_id === +tor.goal
                                  )
                                    ? true
                                    : +tor.goal === player.player_id
                                    ? true
                                    : false
                                }
                              >
                                {getTeam(player.team_id)}: #{player.nummer}{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs="auto" className="ps-0">
                    <FormControl size="small">
                      <InputLabel>Art</InputLabel>
                      <Select
                        label="Team"
                        value={tor.art}
                        name="art"
                        onChange={(e) => handleChangeGoals(e.target, id)}
                      >
                        <MenuItem value={1}>Tor</MenuItem>
                        <MenuItem value={2}>Überzahltor</MenuItem>
                        <MenuItem value={3}>Unterzahltor</MenuItem>
                        <MenuItem value={4}>Emptynet</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              );
            })}
            <div className="d-block text-end">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setEdit(4);
                  saveGoals();
                }}
              >
                speichern & weiter
              </Button>
            </div>
          </>
        )}
        {edit === 4 && (
          <>
            <div className="h3">Statistiken</div>
            <div className="h4">Strafen</div>
            {strafen.map((strafe, id) => {
              return (
                <Row key={id} className="mb-2">
                  <Col xs="auto">
                    <RemoveCircleOutlineIcon
                      fontSize="sm"
                      style={{ cursor: "pointer" }}
                      onClick={() => HandleRemoveStrafe(id)}
                    />
                  </Col>
                  <Col xs="auto" className="h5 align-self-center">
                    {id + 1}.
                  </Col>
                  <Col>
                    <FormControl size="small">
                      <InputLabel>Spieler</InputLabel>
                      <Select
                        label="Spieler"
                        value={strafe.player}
                        name="player"
                        onChange={(e) => handleChangeStrafen(e.target, id)}
                      >
                        <MenuItem value={0}>bitte wählen</MenuItem>
                        {homeKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem key={id} value={player.player_id}>
                                {getTeam(player.team_id)}:{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                        {awayKader.map((player, id) => {
                          if (player.anwesend === true)
                            return (
                              <MenuItem key={id} value={player.player_id}>
                                {getTeam(player.team_id)}:{" "}
                                {player.playerDetails.nachname},{" "}
                                {player.playerDetails.vorname}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl size="small">
                      <InputLabel>Strafe</InputLabel>
                      <Select
                        label="Strafe"
                        value={strafe.art}
                        name="art"
                        onChange={(e) => handleChangeStrafen(e.target, id)}
                      >
                        <MenuItem value={0}>bitte wählen</MenuItem>
                        {strafenStore.map((strafe, id) => {
                          return (
                            <MenuItem key={id} value={strafe}>
                              {strafe}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col>
                    <TextField
                      value={strafe.time}
                      name="time"
                      onChange={(e) => handleChangeStrafen(e.target, id)}
                      size="small"
                      type={"number"}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </Col>
                </Row>
              );
            })}
            <div className="d-block text-end">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setEdit(0);
                  saveStrafen();
                }}
              >
                speichern & weiter
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button
          size="sm"
          variant="outline-success"
          disabled={game === 0}
          onClick={saveGame}
        >
          speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GameEditModal;
