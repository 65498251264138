import { SET_PLAN } from "../constants/actionTypes";

const initialState = {
  games: {},
};

const SpielPlan = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAN:
      return { ...state, games: action.payload };

    default:
      return state;
  }
};

export default SpielPlan;
