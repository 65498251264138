import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TeamLogo from "./Logo";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TeamsNav = () => {
  const navigate = useNavigate();
  const teams = useSelector((state) => state.teams.teams);
  return (
    <Row>
      {teams
        .sort((a, b) => a.id - b.id)
        .map((team) => {
          return (
            <Col key={team.id} xs="6" md="3">
              <Card
                onClick={() => navigate(`/team/${team.id}`)}
                style={{
                  cursor: "pointer",
                  background: `linear-gradient(to bottom, transparent 0%, ${team.color} 50%)`,
                }}
                className="mt-2 text-center"
              >
                <span className="pt-2">
                  <TeamLogo detail={true} team={team.id} />
                </span>
                <Card.Body>
                  <Card.Title>{team.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default TeamsNav;
