import { Tooltip } from "@mui/material";
import React from "react";

const Awards = (props) => {
  return (
    <>
      {Object.keys(props.awards).map((awardType) => (
        <Tooltip
          title={props.awards[awardType].map((award) => (
            <div>
              {award.award} {award.season.short}
            </div>
          ))}
          placement="top"
        >
          <img
            width={props.mobile ? "25%" : "35vw"}
            src={require(`../../images/Sonstiges/award.png`)}
            alt={"Award"}
          />
        </Tooltip>
      ))}
    </>
  );
};

export default Awards;
