import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const NavBar = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <div className="d-flex">
        <div
          onClick={() => navigate("/")}
          className="d-flex align-items-center"
          style={{ cursor: "pointer", margin: "0 auto" }}
        >
          <HomeOutlinedIcon fontSize="large" />
        </div>
        <div
          className="d-grid align-items-center text-center text-white shadow-lg bg-dark"
          style={{
            verticalAlign: "center",
            height: "4rem",
            width: "60%",
            //margin: "0 auto",
            borderRadius: "0 0 100px 100px",
          }}
        >
          <div>
            <img
              style={{ width: "50px" }}
              src={require(`../../images/Logos/ghl.png`)}
            />
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ margin: "0 auto" }}
          onClick={() => setOpenModal(true)}
        >
          <AccountCircleOutlinedIcon fontSize="large" />
        </div>
      </div>
      <div className="pt-1 border-opacity-25">
        <Row
          className="pb-2 align-items-center text-center"
          style={{
            minHeight: "3rem",
            background:
              "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.3))",
          }}
        >
          <Col xs="3" className="g-0 gx-lg-4">
            <Button
              className="border-bottom w-100 text-white"
              variant="secondary-outline"
              onClick={() => navigate("tabelle")}
            >
              Tabelle
            </Button>
          </Col>
          <Col xs="3" className="g-0 gx-lg-4">
            <Button
              className="border-bottom w-100 text-white"
              variant="secondary-outline"
              onClick={() => navigate("spielplan")}
            >
              Spielplan
            </Button>
          </Col>
          <Col xs="3" className="g-0 gx-lg-4">
            <Button
              className="border-bottom w-100 text-white"
              variant="secondary-outline"
              onClick={() => navigate("scorer")}
            >
              Statistiken
            </Button>
          </Col>
          <Col xs="3" className="g-0 gx-lg-4">
            <Button
              className="border-bottom w-100 text-white"
              variant="secondary-outline"
              onClick={() => navigate("infos")}
            >
              Infos
            </Button>
          </Col>
        </Row>
      </div>
      <Modal show={openModal} centered onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>als Admin anmelden</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-success">
            anmelden
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavBar;
