import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";

const TeamEditModal = (props) => {
  const [team, setTeam] = useState(0);
  const [name, setName] = useState("");
  const [short, setShort] = useState("");
  const [logo, setLogo] = useState(null);
  const imageInputRef = useRef();
  const [errorImage, setErrorImage] = useState("");
  const [erfolge, setErfolge] = useState("");
  const [color, setColor] = useState("#000000");

  const handleImageChange = (event) => {
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setLogo(null);
      setErrorImage("Bitte wähle eine geeignete Datei");
      return false;
    } else {
      setLogo(event.target.files[0]);
    }
  };

  const resetInput = () => {};
  return (
    <Modal
      className="text-white"
      centered
      show={props.show}
      onHide={props.hide}
    >
      <Modal.Header
        style={{ backgroundColor: "var(--bs-gray-500)" }}
        closeButton
      >
        <Modal.Title>Team bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Row>
          <Col>
            <FormControl size="small">
              <InputLabel>Team</InputLabel>
              <Select
                label="Team"
                value={team}
                onChange={(e) => setTeam(e.target.value)}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <TextField
              disabled={team === 0}
              size="small"
              type="text"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Col>
          <Col>
            <TextField
              disabled={team === 0}
              size="small"
              type="text"
              label="short"
              onChange={(e) => setShort(e.target.value)}
              value={short}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextField
              disabled={team === 0}
              size="small"
              type="text"
              label="Erfolge"
              onChange={(e) => setErfolge(e.target.value)}
              value={erfolge}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Logo</Form.Label>
            <Form.Control
              disabled={team === 0}
              ref={imageInputRef}
              onChange={handleImageChange}
              type="file"
            />
            <div className="text-danger">{errorImage}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="exampleColorInput">Teamfarbe</Form.Label>
            <Form.Control
              disabled={team === 0}
              onChange={(e) => setColor(e.target.value)}
              type="color"
              id="exampleColorInput"
              value={color}
              title="Teamfarbe"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "var(--bs-gray-500)" }}>
        <Button
          disabled={team === 0}
          size="sm"
          onClick={resetInput}
          variant="outline-danger"
        >
          Eingaben löschen
        </Button>
        <Button
          disabled={
            team === 0 || logo === null || name === null || short === null
          }
          size="sm"
          variant="outline-success"
        >
          anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamEditModal;
